<template>
    <div class="ContactComponent">
        <Button label="Contact Us" v-if="!orange" class="p-button-rounded" @click="openContact" />
        <Button label="Contact Us" v-if="orange" class="p-button-rounded p-button-warning" @click="openContact" />
        <Dialog header="Contact The Team Today!" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" v-model:visible="displayContact" :modal="true">
            <div class="centerdiv">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="name" style="width:100%;"/>
                    <label for="name">Name</label>
                </span>
                <span class="p-float-label">
                    <InputText id="email" type="email" v-model="fromEmail" style="width:100%;"/>
                    <label for="email">Email</label>
                </span>
                <span class="p-float-label">
                    <InputText id="subject" type="text" v-model="subject" style="width:100%"/>
                    <label for="subject">Subject</label>
                </span>
                <span class="p-float-label">
                    <TextArea id="body" v-if="!isMobile" v-model="body" :autoResize="true" rows="15" style="width:100%" />
                    <TextArea id="body" v-if="isMobile" v-model="body" rows="5" style="width:100%" />
                    <label for="body">Message</label>
                </span>
            </div>
            <template #footer>
                <Button label="Cancel" icon="pi pi-times" @click="closeContact" class="p-button-text"/>
                <Button label="Send" icon="pi pi-send" @click="sendEmail" :disabled="sendDisabled" autofocus />
            </template>
        </Dialog>
    </div>
</template>

<script>

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import axios from 'axios';

export default ({
    name: 'ContactComponent',
    components: {
        Button,
        Dialog,
        InputText,
        TextArea
    },
    props: {
        orange: Boolean,
        isMobile: Boolean
    },
    setup() {

    },
    created() {
    },
    data() {
        return {
            displayContact: false,
            fromEmail: "",
            subject: "",
            body: "",
            name: "",
            sendDisabled: true
        };
    },
    methods: {
        openContact() {
            this.displayContact = true;
        },
        closeContact() {
            this.displayContact = false;
        },
        setSendDisabled() {
            this.sendDisabled = !this.validateEmail(this.fromEmail) || this.subject.length == 0 || this.body.length == 0 || this.name.length == 0;
        },
        validateEmail(email) {
            return String(email)
                .toLowerCase()
                .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        async sendEmail() {
            this.sendDisabled = true;
            const headers = {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }
            }
            const payload = {
                Name: this.name,
                Email: this.fromEmail,
                Subject: this.subject,
                Body: this.body
            }
            const response = await axios.post("https://public.herotofu.com/v1/a1561010-a1e8-11ed-a31e-753411848f80", payload, headers);
            if(response.status === 200) {
                this.closeContact();
                this.$toast.add({severity:'success', summary: 'Email Sent', detail:'We will get in touch as soon as possible', life: 3000});
                this.resetForm();
            }
            else {
                this.$toast.add({severity:'error', summary: 'Failed', detail:'Email failed to send, please try again.', life: 3000});
                this.setSendDisabled();
            }
        },
        resetForm() {
            this.fromEmail = "";
            this.subject = "";
            this.body = "";
            this.name = "";
        }
    },
    watch: {
        fromEmail: function() {
            this.setSendDisabled();
        },
        subject: function() {
            this.setSendDisabled();
        },
        body: function() {
            this.setSendDisabled();
        },
        name: function() {
            this.setSendDisabled();
        }
    }
})
</script>

<style scoped>

.p-float-label {
    margin-top:30px;
}

.centerdiv {
    width: 80%;
    margin: auto;
}

</style>
