<template>
    <div class="ServicesExpandedMobile">
        <div class="even" id="section-one">
            <div class="centerblock">
                <img src="../assets/workapplicationslive.jpg" />
                <div>
                    <h4>Mobile Productivity Solutions</h4>
                    <p>
                        Need a custom solution for a mobile device? We can work with you to create the perfect application on Android or iOS. Integrate with existing buisness software, ERP systems and Databases, or create these from scratch. Take advantage of mobile hardware and the latest software technologies to overcome unique problems. Speed up work flows and reduce time it takes to deliver to you customers.
                    </p>
                    <p>
                        Do you have an existing application which needs maintenance or new features? We'll work with existing software to take it from what it is to what it should be.
                    </p>
                    <ContactComponent :orange="false" :is-mobile="true"/>
                </div>
            </div>
        </div>
        <div class="odd" id="section-two">
            <div class="centerblock">
                <img src="../assets/fullstacklive.jpg" />
                <div style="color:white">
                    <h4>Desktop Productivity Solutions</h4>
                    <p>
                        From business requirements to end product. We'll work with you to get the full idea of what your business needs. Do your employees spend too much time filling out forms? Editing reports? Creating documents? We can speed all this up with the right software.
                    </p>
                    <p>
                        Link the user experience directly with your ERP systems and databases. Don't have these? We can work with you to find out what your software or business needs, bare minimum or complete product. Integrate with mobile applications for a complete ecosystem.
                    </p>
                    <ContactComponent :orange="true" :is-mobile="true"/>
                </div>
            </div>
        </div>
        <div class="even" id="section-three">
            <div class="centerblock">
                <img src="../assets/ecommercelive.jpg" />
                <div>
                    <h4>Ecommerce Applications</h4>
                    <p>
                        Starting an online shop? We can setup a simple, userfriendly interface for your customers.
                    </p>
                    <p>
                        Using cloud providers we can create a full database system and tracking software for your product quickly and affordably. We can build software for you to add product and keep track of your transactions with ease. Full reporting dashboards
                    </p>
                    <p>
                        Web, Android, iOS. We got you covered!
                    </p>
                    <ContactComponent :orange="false" :is-mobile="true"/>
                </div>
            </div>
        </div>
        <div class="odd" id="section-four">
            <div class="centerblock">
                <img src="../assets/reportinglive.jpg" />
                <div style="color:white">
                    <h4>Reporting and Statistics</h4>
                    <p>
                        Reporting can be one of the most important features of any business software. Giving you the full picture.
                    </p>
                    <p>
                        Presenting the data you need in easy to view charts, graphs, and tables. Exporting to excel, and easy to use filtering and sorting make assessing your business easy. We can connect stand alone reporting interfaces with any existing systems. If we are building other solutions for your business these can be easily integrated with the software.
                    </p>
                    <ContactComponent :orange="true" :is-mobile="true"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContactComponent from './ContactComponent.vue';

export default ({
    name: 'ServicesExpandedMobile',
    components: {
        ContactComponent
    },
    setup() {

    },
    created() {
    },
    data() {
        return {
        };
    },
    methods: {
    }
})
</script>

<style scoped>
.ServicesExpandedMobile {
    margin-top: 50px;
    width: 100%;
}

.even {
    display: table;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 100vh;
}

.centerblock {
    display: table-cell;
    vertical-align: middle;
    padding: 0vh 5vw 0vh 5vw;
}

.even img {
    width: 75vw;
}

.odd {
    display: table;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 100vh;
    background-color: var(--primary-background-color);
}

.odd img {
    width: 75vw;
}

p {
    font-size: 0.8em;
}

</style>