<template>
    <div class="footer">
        <span class="centerspan"><a href="mailto:sales@tantonsoftware.com"><i class="pi pi-envelope" style="font-size: 2em;"></i><br/>Email Us</a></span>
        <h5>© 2022-2023 Tanton Software Solutions LLC</h5>
    </div>
</template>

<script>

export default ({
    name: 'FooterComponentMobile',
    components: {
    },
    setup() {

    },
    created() {
    },
    data() {
        return {
        };
    },
    methods: {
    }
})
</script>

<style scoped>
.footer {
    display:table;
    width: 100%;
    padding:10px 10px 10px 20px;
}
.footer h5 {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
}
.footer a {
    color: var(--primary-color);
    text-decoration: none;
}

.centerspan {
    display: table;
    vertical-align: middle;
}

</style>