<template>
    <div class="topBarMobile">
        <img class="logo" src="../assets/logo.png"/>
        <h2>Tanton Software Solutions</h2>
    </div>
</template>

<script>

export default ({
    name: 'TopBarMobile',
    components: {
    },
    setup() {

    },
    created() {
    },
    data() {
        return {
        };
    },
    methods: {
    }
})
</script>

<style scoped>
.topBarMobile {
  width: 100%;
  height: 80px;
  display: flex;
}
.topBar h2 {
    vertical-align: middle;
    padding-left:10px;
    text-align: left;
}
.logo {
    vertical-align: middle;
    width: 75px;
    height: 75px;
    padding: 5px;
}

</style>