<template>
    <div class="ServicesDisplay">
        <a href="#" v-scroll-to="'#section-one'">
            <span class="mobileCard">
                Mobile Productivity<br/>
                <EngineeringIcon size="7vw" fillColor="var(--primary-color)"/>
            </span>
        </a>
        <a href="#" v-scroll-to="'#section-two'">
            <span class="mobileCard">
                Desktop Productivity<br/>
                <WorkSolutionsIcon size="7vw" fillColor="var(--primary-color)"/>
            </span>
        </a>
        <a href="#" v-scroll-to="'#section-three'">
            <span class="mobileCard">
                Ecommerce Applications<br/>
                <EcommerceIcon size="7vw" fillColor="var(--primary-color)"/>
            </span>
        </a>
        <a href="#" v-scroll-to="'#section-four'">
            <span class="mobileCard">
                Reporting<br/>
                <AnalyticsIcon size="7vw" fillColor="var(--primary-color)"/>
            </span>
        </a>
    </div>
</template>

<script>

import AnalyticsIcon from '../custom_svgs/AnalyticsIcon.vue';
import EcommerceIcon from '../custom_svgs/EcommerceIcon.vue';
import EngineeringIcon from '../custom_svgs/EngineeringIcon.vue';
import WorkSolutionsIcon from '../custom_svgs/WorkSolutionsIcon.vue';

export default ({
    name: 'ServicesDisplay',
    components: {
        AnalyticsIcon,
        EcommerceIcon,
        EngineeringIcon,
        WorkSolutionsIcon
    },
    setup() {

    },
    created() {
    },
    data() {
        return {
        };
    },
    methods: {
    }
})
</script>

<style scoped>
.solutionCard {
    display: inline-block;
    margin-inline-end: 5vw;
    margin-inline-start: 5vw;
    cursor: pointer;
}

.solutionCard a {
    color: var(--primary-color);
}

.mobileCard {
    display: inline-block;
    margin-inline-start: 2vw;
    margin-inline-end: 2vw;
    padding: 2vw;
    color: var(--primary-color);
    border-radius: 15px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}

</style>