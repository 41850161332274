import { createApp } from 'vue'
import PrimeVue from 'primevue/config';
import Ripple from 'primevue/ripple';
import App from './App.vue'
import ToastService from 'primevue/toastservice';
import VueScrollTo from 'vue-scrollto';

const app = createApp(App);
app.use(PrimeVue, {ripple: true});
app.use(ToastService);
app.use(VueScrollTo);
app.directive('ripple', Ripple);
app.mount('#app');
