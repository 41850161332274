<template>
  <div>
    <component :is="'style'">
      :root{
        --primary-color: {{ "#2c3e50ff" }}!important;
        --primary-background-color: {{ "#011f37ff" }}!important;
      }
    </component>
    <Toast />
    <div v-if="!isMobile()">
      <TopBar />
      <SlideShow />
      <ServicesDisplay />
      <ServicesExpanded />
      <FooterComponent />
    </div>
    <div v-if="isMobile()">
      <TopBarMobile />
      <SlideShowMobile />
      <ServicesDisplayMobile />
      <ServicesExpandedMobile />
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import TopBar from './components/TopBar.vue';
import SlideShow from './components/SlideShow.vue';
import ServicesDisplay from './components/ServicesDisplay.vue';
import ServicesExpanded from './components/ServicesExpanded.vue';
import FooterComponent from './components/FooterComponent.vue';
import TopBarMobile from './components/TopBarMobile.vue';
import SlideShowMobile from './components/SlideShowMobile.vue';
import ServicesDisplayMobile from './components/ServicesDisplayMobile.vue';
import ServicesExpandedMobile from './components/ServicesExpandedMobile.vue';
import Toast from 'primevue/toast';

export default {
  name: 'App',
  components: {
    TopBar,
    SlideShow,
    ServicesDisplay,
    ServicesExpanded,
    FooterComponent,
    TopBarMobile,
    SlideShowMobile,
    ServicesDisplayMobile,
    ServicesExpandedMobile,
    Toast
  },
  setup(){
    const isMobile = () => screen.width <= 760///Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini|Mobile/i.test(navigator.userAgent)
    return {isMobile}
  } 
}
</script>

<style>
@font-face {
  font-family: 'Chopsic';
  src: url('./assets/Chopsic-K6Dp.eot');
  src: url('./assets/Chopsic-K6Dp.woff') format('woff'), 
       url('./assets/Chopsic-K6Dp.ttf') format('truetype');
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
