<template>
    <div class="SlideShow">
        <Galleria :value="images" :numVisible="5" containerStyle="max-width: 100%"
            :showThumbnails="false" :showIndicators="true" :circular="true" :autoPlay="true" :transitionInterval="8000">
            <template #item="slotProps">
                <div style="display:table;">
                    <div class="desc">
                    <h2>{{ slotProps.item.title }}</h2>
                    <p>
                        <i>{{ slotProps.item.alt }}</i><br /><br />
                        {{ slotProps.item.desc }}
                    </p>
                    <ContactComponent :orange="false"/>
                    </div>
                    <img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt" style="max-height:55vh;" />
                </div>
            </template>
        </Galleria>
    </div>
</template>

<script>

import Galleria from 'primevue/galleria';
import ContactComponent from './ContactComponent.vue'

export default ({
    name: 'SlideShow',
    components: {
        Galleria,
        ContactComponent,
    },
    setup() {

    },
    created() {
    },
    data() {
        return {
            images: [
                {
                    itemImageSrc: require('../assets/websolutions.png'),
                    "alt": "Business solutions for web applications",
                    "title": "Web Solutions",
                    "desc": "Productivity, Reporting, Shop, or Media. We'll work with you to design the perfect web application for your business. Connect you existing ERP system or have one designed by scratch."
                },
                {
                    itemImageSrc: require('../assets/mobilesolutions.png'),
                    "alt": "Business solutions for mobile applications",
                    "title": "Mobile Solutions",
                    "desc": "Custom Applications for Android and iOS. Connect with your Web applications or existing business software. Take advantage of mobile hardware to solve logistical problems and increase productivity. Take your ecommerce product mobile and increase user friendliness"
                },
                {
                    itemImageSrc: require('../assets/cloudsolutions.png'),
                    "alt": "Get your application up and running in the cloud",
                    "title": "Cloud Solutions",
                    "desc": "With little cost a full stack solution can be available with cloud solutions. AWS or Microsoft Azure allow for easy, affordable, and scalable solutions. Need it on your own machines? That works too! We'll work with your needs."
                },
                {
                    itemImageSrc: require('../assets/databasesolutions.png'),
                    "alt": "Connect your application with your database SQL/NoSQL",
                    "title": "Database Solutions",
                    "desc": "SQL or NoSQL? If you have existing requirements we can work with either. Not sure which is best? We'll work with you to find the best option for your requirements. In the cloud or on your own servers."
                }
            ],
        };
    },
    methods: {
    },
})
</script>

<style scoped>
.SlideShow {
  width: 100%;
}
.desc {
    display: table-cell;
    vertical-align: middle;
    padding-right: 5vw;
    width: 30vw;
}

</style>
