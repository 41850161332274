<template>
    <span v-bind="$attrs"
          :aria-hidden="!title"
          :aria-label="title"
          class="material-design-icon account-icon"
          role="img"
          @click="$emit('click', $event)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :height="size" :width="size" :fill="fillColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/></svg>
    </span>
  </template>
  
  <script>
  export default {
    name: "EcommerceIcon",
    emits: ['click'],
    props: {
      title: {
        type: String,
      },
      fillColor: {
        type: String,
        default: "currentColor"
      },
      size: {
        type: String,
        default: "24px"
      }
    }
  }
  </script>