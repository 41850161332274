<template>
    <span v-bind="$attrs"
          :aria-hidden="!title"
          :aria-label="title"
          class="material-design-icon account-icon"
          role="img"
          @click="$emit('click', $event)">
          <svg xmlns="http://www.w3.org/2000/svg" :height="size" viewBox="0 0 24 24" :width="size" :fill="fillColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-5h2v5zm4 0h-2v-3h2v3zm0-5h-2v-2h2v2zm4 5h-2V7h2v10z"/></svg>
    </span>
  </template>
  
  <script>
  export default {
    name: "AnalyticsIcon",
    emits: ['click'],
    props: {
      title: {
        type: String,
      },
      fillColor: {
        type: String,
        default: "currentColor"
      },
      size: {
        type: String,
        default: "24px"
      }
    }
  }
  </script>